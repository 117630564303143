import HomeBanners from 'components/Home/HomeBanners';
import HomeHero from 'components/Home/HomeHero';
import HomeOrganizationsSection from 'components/Home/HomeOrganizationsSection';
import HomePartnerSection from 'components/Home/HomePartnerSection';
import HomeVehiclesSection from 'components/Home/HomeVehiclesSection';
import HomeVehicleTypesSection from 'components/Home/HomeVehicleTypesSection';
import SeoWrapper from 'components/Seo/SeoWrapper';
import { Locale } from 'enums/locale';
import { checkAuthentication } from 'guards/checkAuthentication';
import { useComponentMounted } from 'hooks/useComponentMounted';
import { getSeoData } from 'lib/seo/seoData';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import Script from 'next/script';
import { analyticsService } from 'services/AnalyticsService';
import { organizationService } from 'services/OrganizationService';
import { vehicleService } from 'services/VehicleService';
import { Organization } from 'types/organization.type';
import { SeoProps } from 'types/seo.type';
import { VehicleSummary } from 'types/vehicle.type';

export const getServerSideProps: GetServerSideProps = checkAuthentication(
  async (ctx) => {
    const organizationsPromise =
      organizationService.getFrequentOrganizations(ctx);
    const immediatelyReservableVehiclesPromise =
      vehicleService.getImmediatelyReservableVehiclesForHomepage(ctx);

    const path = ctx.locale === Locale.TR ? '/' : '/en';
    const seoPromise = getSeoData('homepage', ctx.locale as Locale, path);

    const [
      organizationsResolve,
      immediatelyReservableVehiclesResolve,
      seoResolve,
    ] = await Promise.allSettled([
      organizationsPromise,
      immediatelyReservableVehiclesPromise,
      seoPromise,
    ]);

    if (seoResolve.status === 'fulfilled') {
      seoResolve.value.helmetSettings.title =
        seoResolve.value.helmetSettings.title.replace('| Teknevia', '');
    }

    ctx.res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    );

    return {
      props: {
        organizations:
          organizationsResolve.status === 'fulfilled'
            ? organizationsResolve.value
            : [],
        immediatelyReservableVehicles:
          immediatelyReservableVehiclesResolve.status === 'fulfilled'
            ? immediatelyReservableVehiclesResolve.value
            : [],
        ...(seoResolve.status === 'fulfilled' ? seoResolve.value : {}),
      },
    };
  }
);

interface HomePageProps extends SeoProps {
  organizations: Organization[];
  immediatelyReservableVehicles: VehicleSummary[];
}

const Home: NextPage<HomePageProps> = ({
  organizations,
  immediatelyReservableVehicles,
  helmetSettings,
  content,
}) => {
  useComponentMounted(() => {
    analyticsService.remarketingHomePageView();
  });
  return (
    <SeoWrapper helmetSettings={helmetSettings} content={content}>
      <Head>
        <link
          rel="alternate"
          href="https://www.teknevia.com"
          hrefLang="x-default"
        />
      </Head>
      <Script
        id="organization-schema"
        type="application/ld+json"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `{"@context":"https://schema.org","@type":"Corporation","name":"Teknevia","alternateName":"Kiralık Tekne ve Yat Kiralama","url":"https://www.teknevia.com/","logo":"https://cdn.teknevia.com/storefront/common/teknevia-logo.png","contactPoint":{"@type":"ContactPoint","telephone":"+90 850 308 15 62","contactType":"customer service","areaServed":"TR","availableLanguage":["en","Turkish"]},"address":{"@type":"PostalAddress","streetAddress":"Mimar Sinan Mah. Küçük Sarmaşık Sok. No:11/3","addressLocality":"Istanbul, Turkey","addressCountry":"TR","postalCode":"34674"},"sameAs":["https://www.facebook.com/people/Teknevia/100075736485964","https://www.instagram.com/teknevia/","https://www.youtube.com/@teknevia","https://www.linkedin.com/company/tekneviateknoloji/","https://tr.pinterest.com/teknevia/"]}`,
        }}
      />
      <HomeHero />
      <HomeBanners />
      <HomeVehiclesSection vehicles={immediatelyReservableVehicles} />
      <HomeOrganizationsSection organizations={organizations} />
      <HomeVehicleTypesSection />
      <HomePartnerSection />
    </SeoWrapper>
  );
};

export default Home;
